export const smartApi = {
	methods: {
		/*		callSmartApi(url, params = {}) {
					this.smartApi(url,params.then,params.finally, params.cdnFiles, params.chargerParCdn)
				},*/
		smartApi(url, params) {
			let thenCallback = this.objVal(params, 'then', false);
			let catchCallback = this.objVal(params, 'catch', false);
			let finallyCallback = this.objVal(params, 'finally', false);
			let cdnFiles = this.objVal(params, 'cdnFiles', false);
			let chargerParCdn = this.objVal(params, 'chargerParCdn', true);
			// Appel à l'api dynamique pour l'url donnée
			let callApi = () => {
				api.get(url).then(handleApiResponse).catch(catchCallback);
			};

			if (!chargerParCdn) {
				return callApi();
			}

			// Gestion de la réponse venant d'un appel cdn ou api
			let handleApiResponse = (response) => {
				// si la réponse contient un chemin vers un fichier de CDN
				if (response.data.cdnFile) {
					// chargeons le contenu de ce fichier pour récupérer les données
					cdn.get(response.data.cdnFile).then(handleApiResponse);
				} else {
					// sinon, utilisons les données renvoyées
					thenCallback(response.data);
					if (finallyCallback) {
						finallyCallback();
					}
				}
			};


			let finalCdnFile = false;
			// la donnée que l'on recherche a des fichiers de cdn utilisables
			if (cdnFiles) {
				let cdnFile;
				// est-ce qu'un fichier de cdn correspondn à la requete demandée ?
				if (cdnFile = this.findCdnFile(url, cdnFiles)) {
					finalCdnFile = cdnFile;
				}
			}

			// aucun fichier cdn fourni ne semble utilisable. Essayons alors de deviner son url
			if (!finalCdnFile && this.useCdn()) {
				finalCdnFile = this.cdnUrl(url);
			}
			if (finalCdnFile) {
				finalCdnFile = this.urlRefresh(finalCdnFile);
				// un fichier de cdn a été identifié. On envoie la requete vers ce fichier
				cdn.get(finalCdnFile).then(handleApiResponse).catch(error => {
					// en cas d'erreur, on fait un appel à l'api dynamique
					callApi()
				});
			} else {
				// si aucun fichier de cdn n'a été identifié, on fait un appel direct à l'api dynamique
				callApi()
			}

		},
		findCdnFile(url, cdnFiles) {
			if (this.useCdn() && cdnFiles) {
				url = this.cdnUrl(url);
				return cdnFiles.find(cdnFile => {
					return cdnFile.indexOf(url) > -1
				});
			}
		},
		cdnUrl(url) {
			return url + '.json';
		},
		urlRefresh(url) {
			if (url) {
				let c = '?';
				if (url.includes('?')) {
					c = '&';
				}
				return url + c + Math.ceil(Math.random() * 100000000)
			}
		},
		useCdn() {
			return process.env.VUE_APP_USE_CDN == 'true';
		}
	}
}