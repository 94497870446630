export const dateMixin = {
    data() {
        return {
            maintenant: false,
            unJour : 3600 * 24 * 1000
        }
    },
    mounted() {
        this.changerMaintenant();
    },
    computed: {
    },
    methods: {
        convertDate(dateStr) {
            if(dateStr instanceof Date) {
                return dateStr;
            }
            let tab = dateStr.split(/\D/);
            let y,m,d,h,i,s;
            y=tab[0];
            m=tab[1];
            d=tab[2];
            if(tab.length>3) {
                h=tab[3];
                i=tab[4];
                s=tab[5];
            } else {
                h=i=s='00';
            }

            let ret = y+'-'+m+'-'+d+'T'+h+':'+i+':'+s;
            return ret;
        },
        changerMaintenant() {
            // console.log('maintenant')
            this.maintenant = new Date();
        },
        dateInput(dateStr) {
            let d = new Date(this.convertDate(dateStr)),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        },
        dateFormatee(dateStr) {
            let date = new Date(this.convertDate(dateStr));
            let dateFormatee = date.toLocaleDateString('fr-FR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
            let mois = date.toLocaleDateString('fr-FR', { month: 'long' });
            return dateFormatee.replace(' 1 ' + mois, ' 1er ' + mois);
        },
        dateHeureFormateeCourte(dateStr) {
            let annee = new Date().getFullYear();
            let date = new Date(this.convertDate(dateStr));
            let dateFormatee = date.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' });
            let mois = date.toLocaleDateString('fr-FR', { month: 'long' });

            
            let ret = dateFormatee.replace(' 1 ' + mois, ' 1er ' + mois);
            ret = ret.replace(' '+annee,'');
            
            let heure = date.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });
            ret+=' à '+heure
            return 'Le '+ret;
        },
        dateHeureFormatee(dateStr) {
            let date = new Date(this.convertDate(dateStr));
            let heure = date.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });
            return 'Le ' + this.dateFormatee(date) + ' à ' + heure;
        },
        timeSince(dateStr, maintenant = null) {
            if (maintenant == null) {
                maintenant = new Date();
            }
            let date = new Date(this.convertDate(dateStr));
            if (maintenant - date > this.unJour) {
                return this.dateHeureFormateeCourte(date);
            } else {
                let seconds = Math.floor((maintenant - date) / 1000);

                let interval = seconds / 31536000;
                let prefix = 'Il y a ';
                if (interval > 1) {
                    return prefix + this.pluriel(Math.floor(interval), "an");
                }
                interval = seconds / 2592000;
                if (interval > 1) {
                    return prefix + this.pluriel(Math.floor(interval), "mois");
                }
                interval = seconds / 86400;
                if (interval > 1) {
                    return prefix + this.pluriel(Math.floor(interval), "jour");
                }
                interval = seconds / 3600;
                if (interval > 1) {
                    return prefix + this.pluriel(Math.floor(interval), "heure");
                }
                interval = seconds / 60;
                if (interval > 1) {
                    return prefix + this.pluriel(Math.floor(interval), "minute");
                }
                if (seconds = Math.floor(seconds)) {
                    return prefix + this.pluriel(Math.floor(seconds), "seconde");
                } else {
                    return "à l'instant";
                }
            }
        },
    }
}