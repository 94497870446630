import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import VueMatomo from 'vue-matomo'
Vue.use(require('vue-cookies'))


/*Vue.use(VueMatomo, {
  // Configure your matomo server and site by providing
  host: 'https://matomo.sopress.net',
  siteId: 12,

  // Enables automatically registering pageviews on the router
  router: router,


  // Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
  // Default: false
  enableHeartBeatTimer: false,

  // Set the heartbeat timer interval
  // Default: 15
  heartBeatTimerInterval: 15,


  // UserID passed to Matomo (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)
  // Default: undefined
  userId: undefined,


  // A list of pre-initialization actions that run before matomo is loaded
  // Default: []
  // Example: [
  //   ['API_method_name', parameter_list],
  //   ['setCustomVariable','1','VisitorType','Member'],
  //   ['appendToTrackingUrl', 'new_visit=1'],
  //   etc.
  // ]
  preInitActions: []
});

/*<button @click="handleConsent()">Accept Cookies</button>

handleConsent() {
  this.$matomo.rememberConsentGiven()
}*/

import 'bulma';

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

import { dateMixin } from './mixins/date'
Vue.mixin(dateMixin);

import { tabs } from './mixins/tabs'
Vue.mixin(tabs);

import { evenements } from './mixins/evenements'
Vue.mixin(evenements);

import { smartApi } from './mixins/smartApi'
Vue.mixin(smartApi);

import { outils } from './mixins/outils'
Vue.mixin(outils);

import { auth } from './mixins/auth'
Vue.mixin(auth);

import { commons } from './mixins/commons'
Vue.mixin(commons);

import { members } from './mixins/members'
Vue.mixin(members);

import { comments } from './mixins/comments'
Vue.mixin(comments);

window.axios = axios;
window.api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: { Authorization: process.env.VUE_APP_AUTH }
});


window.cdn = axios.create({
  baseURL: process.env.VUE_APP_CDN,
});


window.ip = axios.create({
  baseURL: 'https://tools.sopress.net/ip/',
})


Vue.prototype.$bus = new Vue()

Vue.config.productionTip = false

Vue.component('Chargement', () => import('@/components/Divers/Chargement.vue'));
Vue.component('Icone', () => import('@/components/Divers/Icone.vue'));
Vue.component('ReglageChamp', () => import('@/components/Reglages/ReglageChamp.vue'));
Vue.component('MemberStatus', () => import('@/components/Members/MemberStatus.vue'));
Vue.component('MemberLevel', () => import('@/components/Members/MemberLevel.vue'));





window.vm = new Vue({
  router,
  store,
  render: h => h(App),
  beforeCreate() {

    window.cdn.interceptors.request.use((config) => {
      config.baseURL = config.baseURL.replace('%site', this.$store.state.site);
      return config
    });
    window.api.interceptors.request.use((config) => {
      config.data = config.data || {};
      config.baseURL = config.baseURL.replace('%site', this.$store.state.site);
      let addToUrl = false;
      if(config.method == 'get') {
        addToUrl=true;
      }
      if(config.url == 'blob') {
        addToUrl=true;
      }
      let token = this.$store.state.token;
      if (token) {
        if (addToUrl) {
          config.url += (config.url.includes('?') ? '&' : '?') + 'token=' + token;
        } else {
          config.data.token = token;
        }
      }
      // config.url += (config.url.includes('?') ? '&' : '?') + 'isLive=' + (this.isLive ? 'true' : 'false');
      if (this.storyId) {
        if (!('storyId' in config.data)) {
          if (!config.url.includes('storyId') && !config.url.includes(this.storyId)) {
            config.url += (config.url.includes('?') ? '&' : '?') + 'storyId=' + this.storyId;
          }
        }
      }
      if (!this.useCdn()) {
        config.url += (config.url.includes('?') ? '&' : '?') + 'nocdn=true';
      }
      return config
    }, error => {
      return console.log(error)
    })
  }
}).$mount('#app')
