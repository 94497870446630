export const outils = {
    data() {
        return {
            hiddenOnMobile: 'is-hidden-mobile is-hidden-tablet-only is-hidden-touch'
        }
    },
    methods: {
        on(event, listener) {
            // console.log(event);
            return this.$bus.$on(event, listener);
        },
        off(event, listener) {
            return this.$bus.$off(event, listener);
        },
        emit(event) {
            return this.$bus.$emit(event);
        },
        getSelectionText(commId) {
            let text = "";
            if (window.getSelection) {
                try {
                    //                    console.log(window.getSelection())
                    let body = window.getSelection().focusNode.parentNode.closest('.body');
                    if (body) {
                        let commentaire = body.closest('.commentaire');
                        if (commentaire) {
                            if (commentaire.dataset.id == commId) {
                                text = window.getSelection().toString();
                            }
                        }
                    }
                } catch (e) {
                }
                //            } else if (document.selection && document.selection.type != "Control") {
                //              text = document.selection.createRange().text;
            }
            return text;
        }, objVal(obj, val, defaut) {
            defaut = typeof defaut !== 'undefined' ? defaut : false;
            try {
                if (obj.hasOwnProperty(val)) {
                    return obj[val];
                } else {
                    return defaut;
                }
            } catch (e) {
                return defaut;
            }
        },
        pluriel(valeur, texte) {
            let last = texte.substr(-1);
            if (valeur > 1) {
                if (last != 's') {
                    texte == 's';
                }
            }

            return valeur + ' ' + texte;
        },
        loadJs(url, callback) {
            const script = document.createElement("script");
            script.setAttribute("src", url);
            script.async = true;
            script.onload = callback;
            document.head.appendChild(script);
        },
        pluriel(qte, texte, aucun = false) {
            if (qte > 1) {
                texte = (texte + ' ').replace(/ /g, 's ').trim();
            }
            if (aucun && qte == 0) {
                qte = aucun;
            }
            return qte + ' ' + texte;
        },
        replaceRecursive(comments, newComment) {
            return comments.some((comment, index) => {
                if (comment.id == newComment.id) {
                    this.copyObject(newComment, comments[index])
                    return true;
                }
                if (comment.answers) {
                    return this.replaceRecursive(comment.answers, newComment);
                }
            })
        },
        setRecursive(comments, newComment) {
            return comments.some((comment, index) => {
                if (comment.id == newComment.id) {
                    for (const [key, value] of Object.entries(newComment)) {
                        if (key != 'id') {
                            console.log(key, value)
                            comments[index][key] = value
                        }
                    }
                    return true;
                }
                if (comment.answers) {
                    return this.setRecursive(comment.answers, newComment);
                }
            })
        },
        copyObject(from, to) {
            for (let key in from) {
                to[key] = from[key];
            }
        },
        scrollTo(selector) {
            setTimeout(() => {
                let elt = document.querySelector(selector);
                if (elt) {
                    console.log(elt)
                    window.scrollTo(0, elt.offsetTop);
                }
            }, 1000)
        },
        getOffsetTop(selector) {
            let element = typeof selector == 'object' ? selector : document.querySelector(selector);
            let offsetTop = 0;
            while (element) {
                offsetTop += element.offsetTop;
                element = element.offsetParent;
            }
            return offsetTop;
        },
        stringToColour(str) {
            if (str) {
                var hash = 0;
                for (var i = 0; i < str.length; i++) {
                    hash = str.charCodeAt(i) + ((hash << 5) - hash);
                }
                var colour = '#';
                for (var i = 0; i < 3; i++) {
                    var value = (hash >> (i * 8)) & 0xFF;
                    colour += ('00' + value.toString(16)).substr(-2);
                }
                return colour;
            } else {
                return '#000000';
            }
        },
        colorInverse(color, lightColor = '#ffffff', darkColor = '#000000') {
            return color == lightColor ? darkColor : lightColor;
        },
        textColor(bgColor, lightColor = '#ffffff', darkColor = '#000000') {
            var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
            var r = parseInt(color.substring(0, 2), 16); // hexToR
            var g = parseInt(color.substring(2, 4), 16); // hexToG
            var b = parseInt(color.substring(4, 6), 16); // hexToB
            return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ? darkColor : lightColor;
        },
        siteUrl() {
            return location.protocol + '//' + location.host
        },
        decodeEntities(str) {
            if (str && typeof str === 'string') {
                let element = document.createElement('div');
                str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
                str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
                element.innerHTML = str;
                str = element.textContent;
                element.textContent = '';
            }
            return str;
        },
        attachEvent(callback) {
            if (!window.attachEventDone) {
                window.attachEventDone = true;
                let _callback = (e) => {
                    try {
                        callback(e);
                    } catch (error) {
                        console.log(error)
                    }
                }
                if (window.addEventListener) {
                    window.addEventListener('message', callback, false);
                } else if (window.attachEvent) {
                    window.attachEvent('onmessage', callback);
                }
            }
        }
    }
}