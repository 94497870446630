export const tabs = {
    data() {
        return {
        }
    },
    computed: {
        tabName() {
            return 'tab-'+this.$store.state.tab;
        },
        isTabWithComments() {
            if (this.$store.state.tab == 'all') {
                return true;
            }
            if (this.$store.state.tab == 'top') {
                return true;
            }
        },
    },
    methods: {
        setTab() {
            let tab;
            if (this.$route.params.tab) {
                tab = this.$route.params.tab;
            } else {
                tab = "all";
            }
            this.$store.commit('setTab', tab);
        },

        changeTab(tab) {
            let name = "StoryTab";
            if (tab == "all") {
                name = "Story";
            }
            let query = this.$route.query;

            let params = this.$route.params;
            params.tab = tab;
            this.$router.push({ name, params, query });

        },

        isTabActive(nom) {
            if (this.$store.state.tab == nom) {
                return "is-active";
            }
        }

    }
}