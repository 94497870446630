export const evenements = {
    methods : {
        evenement(nom, payload=false){
            try {
                this.triggerEvent(nom, payload);
              } catch (e) {
                console.log(nom, payload);
              }
    
        },
        triggerEvent(event, payload = false) {
            try {
                console.log('triggerEvent', event, payload );
                parent.postMessage({
                    event,
                    payload
                }, this.$store.state.domain);
            } catch (e) {
                console.log(e);
            }
        }

    }
}