export const comments = {
    computed: {
    },
    methods: {
        setFolded(comment, folded) {
            let folds = this.getFolds();
            if(folded) {
                folds[comment.id]=true;
            } else {
                delete folds[comment.id];
            }
            sessionStorage.setItem('folds', JSON.stringify(folds));
            if('folds' in window) {
                delete window.folds;
            }
        },
        isFolded(comment){
            let folds = this.getFolds();
            if(comment.id in folds) {
                return true;
            }
        },
        getFolds(){
            if(!('folds' in window)) {
                let folds = sessionStorage.getItem('folds');
                if(!folds){
                    folds = {};
                } else {
                    folds = JSON.parse(folds);
                }
                window.folds = folds;
            }
            return window.folds;
        }
    }
}