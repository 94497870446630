<template>
<section class="hero is-fullheight">
  <div class="hero-body">
    <div class="container">
 	 	<center><img src="@/assets/logo.png"></center>

    </div>
  </div>
</section>
</template>

<script>

export default {
  name: 'Home'
}
</script>
